import { useState } from "react";
import { Spinner } from "react-bootstrap";
import apiClient from "../../../lib/api";
import { useFbInitiateCheckout } from "../../../lib/fpixel";

const ButtonCheckout = ({
  priceId,
  plan,
  subscriptionType = "monthly",
  mode = "subscription",
  buttonName = "Subscribe",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = async () => {
    setIsLoading(true);

    try {
      const res = await apiClient.post("/stripe/create-checkout", {
        priceId,
        plan,
        subscriptionType,
        mode,
        successUrl: `${window.location.href}?status=success&priceId=${priceId}`,
        cancelUrl: window.location.href,
      });

      useFbInitiateCheckout();
      window.location.href = res.url;
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  return (
    <button
      className={`shadow btn btn-primary btn-block tw-p-3 rounded-lg plausible-event-name=Purchase-${plan}`}
      onClick={() => handlePayment()}
    >
      {isLoading && <Spinner animation="border" size="sm" className="me-2" />}
      {buttonName}
    </button>
  );
};

export default ButtonCheckout;
